import { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import ReactDOMServer from "react-dom/server"
// import BarLoader from "react-spinners/BarLoader";
//import mapboxgl, { LngLat } from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css"
import { useGetAllMeetingPointsCoordsOfTourGroup } from "../../../../reactQueryHooks"
//imported in index.html
// @ts-ignore
mapboxgl.accessToken =
  "pk.eyJ1Ijoibm9zcGlwaSIsImEiOiJjbGVoOWc2MWMwYjVzM29xZjJkNjlnZzU3In0.ygeBpLtHj3dWNMii9EL1qw"

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  width: 90%;
  height: 90%;
  max-width: 650px;
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`

const MapWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const MapContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`

//---------------------------------------------------------

const PopupContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1px 5px 0 5px;
  //shadow
  //blurred bg with transparency
  background-color: transparent;

  span {
    padding: 0;
    margin: 0;
  }
`

const PopupItem = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  i {
    font-size: 12px;
  }
`

//---------------------------------------------------------

const Popup = ({
  index,
  time,
  name,
}: {
  index: number
  time: string
  name: string
}) => {
  return (
    <PopupContainer>
      <PopupItem
        style={{
          fontWeight: "bold",
        }}
      >
        <i
          style={{
            color: "#1976d1",
          }}
          className="fa-solid fa-hashtag"
        ></i>
        {index + 1}
      </PopupItem>
      <PopupItem>
        <i className="fa-regular fa-clock"></i>
        {time}
      </PopupItem>
      <PopupItem>
        <i className="fa-solid fa-location-dot"></i>
        {name}
      </PopupItem>
    </PopupContainer>
  )
}

const PickupsMapModal = ({
  close,
  tourGroupId,
}: {
  close: () => void
  tourGroupId: string
}) => {
  const mapContainer = useRef<HTMLDivElement>(null)
  // @ts-ignore
  const [map, setMap] = useState<mapboxgl.Map>()

  const {
    data: meetingPoints,
    isFetching,
    isError,
    error,
  } = useGetAllMeetingPointsCoordsOfTourGroup(tourGroupId)

  const errorMessage =
    (error as any)?.response?.data || "Error fetching pickup plan"

  // MEETING POINTS EXAMPLE
  //   [
  //   ({
  //     latitude: "37.97340314524558",
  //     longitude: "23.73371556375749",
  //   },
  //   {
  //     latitude: "37.97340314524558",
  //     longitude: "23.73371556375749",
  //   },
  //   {
  //     latitude: "37.97340314524558",
  //     longitude: "23.73371556375749",
  //   },
  //   {
  //     latitude: "37.97340314524558",
  //     longitude: "23.73371556375749",
  //   })
  // ]

  useEffect(() => {
    if (mapContainer.current) {
      // @ts-ignore
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [23.72656913921858, 37.97340314524558],
        zoom: 10,
      })
      setMap(newMap)
    }
  }, [])

  useEffect(() => {
    if (!map || !meetingPoints) return

    // Add markers for each meeting point
    meetingPoints.forEach(
      (
        point: {
          latitude: number
          longitude: number
          name: string
          time: string
        },
        index
      ) => {
        // @ts-ignore
        new mapboxgl.Marker()
          .setLngLat([point.longitude, point.latitude])
          .addTo(map)
          .setPopup(
            // @ts-ignore
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false,
            }).setHTML(
              ReactDOMServer.renderToString(
                <Popup index={index} time={point.time} name={point.name} />
              )
            )
          )
          .togglePopup()
      }
    )

    // Create a GeoJSON feature for the polyline
    const coordinates: [number, number][] = meetingPoints.map(
      (point: { latitude: number; longitude: number }) => [
        point.longitude,
        point.latitude,
      ]
    )
    // @ts-ignore
    const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
    map.fitBounds(bounds, {
      padding: 20, // Padding in pixels to add around the markers (to avoid markers touching the edge)
      maxZoom: 15, // Optional: limit how close you can zoom in
    })

    // Add the line source to the map
    map.on("load", () => {
      map.addSource("line", {
        type: "geojson",
        data: {
          type: "Feature",
          properties: {},
          geometry: {
            type: "LineString",
            coordinates: coordinates,
          },
        },
      })

      // Add the line layer
      map.addLayer({
        id: "line",
        type: "line",
        source: "line",
        layout: {
          "line-join": "round",
          "line-cap": "round",
        },
        paint: {
          "line-color": "dodgerblue",
          "line-width": 2,
          "line-dasharray": [2, 2],
        },
      })
    })
  }, [map, meetingPoints])

  return (
    <Wrapper>
      <Modal>
        <CloseContainer>
          {isFetching && (
            <span
              style={{
                color: "dodgerblue",
              }}
            >
              Fetching pickup plan...
            </span>
          )}
          {isError && (
            <span
              style={{
                color: "indianred",
              }}
            >
              {errorMessage}
            </span>
          )}
          <CloseButton onClick={close}>
            <i className="fas fa-times"></i>
          </CloseButton>
        </CloseContainer>

        <MapWrapper>
          <MapContainer ref={mapContainer} />
        </MapWrapper>
      </Modal>
    </Wrapper>
  )
}

export default PickupsMapModal
