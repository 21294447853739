import { useState, useEffect, useRef } from "react"
import { Formik } from "formik"
import * as Yup from "yup"
import styled from "styled-components"
import { motion } from "framer-motion"
import { v4 as uuidv4 } from "uuid"
import _ from "lodash"
import moment from "moment"
import {
  useFetchEntities,
  useAddTourGroupFromGuidePlan,
} from "../../../../reactQueryHooks"
import AddTourArrangementActionList from "./AddTourArrangementActionList"
import PlaylistAddCircleIcon from "@mui/icons-material/PlaylistAddCircle"
import { TourArrangement } from "./AddTourArrangementActionList"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import CustomSelect from "./CustomSelect"
import CustomDropdown from "../../settings/content/users/CustomDropdown"

//---------------------------------------------------------

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`

const Modal = styled(motion.div)`
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
`

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

const CloseContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

const CloseButton = styled.button`
  background-color: whitesmoke;
  border: none;
  color: white;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  i {
    color: black;
    font-size: 15px;
  }
  &:hover {
    i {
      color: indianred;
    }
  }
`
const RowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
`

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;

  @media (max-width: 400px) {
    flex-direction: column; // Switch to column direction on smaller screens
    height: auto; // Adjust height to accommodate column layout
  }
  flex-wrap: wrap;
  //label
  .MuiFormControlLabel-label {
    font-size: 13px;
    user-select: none;
  }
  //checkbox container
  .MuiButtonBase-root {
    //padding: 2px !important;
  }
  //checkbox
  .MuiSvgIcon-root {
    font-size: 19px;
  }
`

const ItemContainer = styled.div<{
  isValidated?: boolean
  isDropdownOpen?: boolean
}>`
  flex: 1;
  min-height: 40px;
  border: 1px solid rgb(228, 228, 228);
  border-radius: 4px;
  background-color: ${(props) =>
    props.isValidated ? "#daffda" : "whitesmoke"};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 5px;
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
  .fa-angle-down {
    transform: ${({ isDropdownOpen }) =>
      isDropdownOpen ? "rotateX(180deg)" : "rotateX(0deg)"};
    transition: transform 0.3s ease-in-out;
    font-size: 12px;
    color: black;
  }
  overflow: hidden;
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .input {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }
  select {
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 13px;
    &:focus {
      outline: none;
    }
  }
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: #b4b4b4;
  color: white;
  padding: 0 8px;
  cursor: pointer;
  font-size: 13px;
  &:hover {
    filter: brightness(1.1);
  }
  &:focus {
    outline: none;
  }
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`

const SelectorContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`

const SelectorButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 1px solid lightgray;
  border-radius: 5px;
  position: relative;
`

const SelectorButton = styled.button<{
  selected: boolean
}>`
  text-align: center;
  //background-color: #6eafbb;
  border: none;
  /* color: ${({ selected }) =>
    selected ? "rgb(14 73 14)" : "rgb(102 45 45)"};
  font-weight: ${({ selected }) => (selected ? "bold" : "normal")}; */
  font-size: 13px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
`

const SelectorIndicatorBottomLine = styled.div`
  width: 100%;
  height: 3px;
  background-color: darkgreen;
  position: absolute;
  bottom: 0;
`

//---------------------------------------------------------

const AddTourArrangementModal = ({
  close,
  refetch,
}: {
  close: () => void
  refetch: () => void
}) => {
  const [tourArrangementList, setTourArrangementList] = useState<
    TourArrangement[]
  >([])
  const sortedTourArrangementListByDate = _.sortBy(tourArrangementList, "date")
  const [submittingItems, setSubmittingItems] = useState<any>([Boolean])
  const [submittedItems, setSubmittedItems] = useState<any>([Boolean])
  const [range, setRange] = useState([moment(), moment()])
  const [isRange, setIsRange] = useState(false)
  const [days, setDays] = useState([true, true, true, true, true, true, true])
  const [submittedErrorItems, setSubmittedErrorItems] = useState<
    Array<boolean | string>
  >([])
  const [isProductDropdownOpen, setIsProductDropdownOpen] = useState(false)
  const [isOptionDropdownOpen, setIsOptionDropdownOpen] = useState(false)
  const productDropdownRef = useRef<{ close: () => void }>(null)
  const optionsDropdownRef = useRef<{ close: () => void }>(null)
  const [isGuideDropdownOpen, setIsGuideDropdownOpen] = useState(false)
  const guideDropdownRef = useRef<{ close: () => void }>(null)
  const {
    data: entities,
  }: {
    data: any
  } = useFetchEntities()

  useEffect(() => {
    const sortedTourArrangementListByDate = _.sortBy(
      tourArrangementList,
      "date"
    )
    const items = sortedTourArrangementListByDate.map(
      (tourArrangement, index) => false
    )
    setSubmittingItems(items)
    setSubmittedItems(items)
    setSubmittedErrorItems(items)
  }, [tourArrangementList])

  const products = entities?.data?.products.filter((product: any) => {
    return product.isGuided
  })
  const tour_guides = entities?.data?.staff.filter((staff: any) =>
    staff.roles.some((role: any) => role._id === "636d1329f2535b158a9acd20")
  )

  const { mutateAsync: addTourGroup } = useAddTourGroupFromGuidePlan()

  //const resetItems resets the submittingItems, submittedItems, and submittedErrorItems to their initial state
  const resetItems = async () => {
    const items = sortedTourArrangementListByDate.map(
      (tourArrangement, index) => false
    )
    setSubmittingItems(items)
    setSubmittedItems(items)
    setSubmittedErrorItems(items)
  }

  const initiateSubmission = async () => {
    //run the mutation for each item in the list
    //for each item, set the submittingItems[index] to true
    //if successful, set the submittedItems[index] to true
    //if error, set the submittedErrorItems[index] to response.data

    try {
      await resetItems()

      for (let i = 0; i < sortedTourArrangementListByDate.length; i++) {
        try {
          setSubmittingItems((prev: boolean[]) => {
            const newItems = [...prev]
            newItems[i] = true
            return newItems
          })

          await addTourGroup(sortedTourArrangementListByDate[i] as any)

          setSubmittedItems((prev: boolean[]) => {
            const newItems = [...prev]
            newItems[i] = true
            return newItems
          })
        } catch (err: any) {
          setSubmittedErrorItems((prev: (string | boolean)[]) => {
            const newItems = [...prev]
            newItems[i] = err.response?.data || "Unknown error"
            return newItems
          })
        } finally {
          setSubmittingItems((prev: boolean[]) => {
            const newItems = [...prev]
            newItems[i] = false
            return newItems
          })
        }
      }
    } catch (error: any) {
      // Handle any errors that occur outside of the loop
      console.error("An unexpected error occurred:", error)
      alert(error.toString())
    } finally {
      refetch()
    }
  }

  return (
    <Formik
      validateOnMount
      enableReinitialize
      initialValues={{
        product_id: "",
        option_id: "",
        start_time_id: "",
        date: moment().format("YYYY-MM-DD"),
        time: "",
        guide_id: null,
        index: 1,
      }}
      validationSchema={Yup.object({
        product_id: Yup.string().required("Product is required"),
        option_id: Yup.string().required("Option is required"),
        start_time_id: Yup.string().required("Start time id is required"),
        date: Yup.string().required("Date is required"),
        time: Yup.string().required("Time is required"),
        index: Yup.number().required("Group is required"),
        guide: Yup.string(),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue }) => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        resetForm,
        isSubmitting,
        validateForm,
        isValid,
      }) => {
        const product = products.find(
          (product: any) => product._id === values.product_id
        )
        const option = product?.options.find(
          (option: any) => option.bokun_code === values.option_id
        )
        const selectedProductTitle = product?.title
        const selectedOptionTitle = option?.title
        const selectedProductTimeSlots = product?.time_slots_with_range
        const selectedGuideName = entities?.data?.staff.find(
          (user: any) => user._id === values.guide_id
        )?.name

        return (
          <Wrapper>
            <Modal
              initial={{ opacity: 0, scale: 0 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{
                opacity: { duration: 0.2 },
                scale: {
                  //type: "spring",
                  // stiffness: 300, // Adjust stiffness for more/less bounce
                  // damping: 20, // Adjust damping for faster/slower settle
                  // duration: 0.02,
                },
              }}
            >
              <CloseContainer>
                <CloseButton onClick={close}>
                  <i className="fas fa-times"></i>
                </CloseButton>
              </CloseContainer>
              <ContentContainer>
                <RowsContainer>
                  <SelectorContainer>
                    <SelectorButtonContainer>
                      <SelectorButton
                        onClick={() => {
                          setIsRange(false)
                        }}
                        selected={!isRange}
                      >
                        SINGLE
                      </SelectorButton>
                      {!isRange && <SelectorIndicatorBottomLine />}
                    </SelectorButtonContainer>
                    <SelectorButtonContainer>
                      <SelectorButton
                        onClick={() => {
                          setIsRange(true)
                        }}
                        selected={isRange}
                      >
                        RANGE
                      </SelectorButton>
                      {isRange && <SelectorIndicatorBottomLine />}
                    </SelectorButtonContainer>
                  </SelectorContainer>
                  {isRange && (
                    <>
                      <Row>
                        <ItemContainer isValidated={values.date ? true : false}>
                          <input
                            className="input"
                            type="date"
                            name="date"
                            value={range[0].format("YYYY-MM-DD")}
                            onChange={(date) => {
                              const isValid = moment(
                                date.target.value
                              ).isSameOrBefore(range[1])
                              if (isValid) {
                                setRange([moment(date.target.value), range[1]])
                              } else {
                                alert("Start date must be before end date")
                              }
                            }}
                          />
                        </ItemContainer>
                        <ItemContainer isValidated={values.date ? true : false}>
                          <input
                            className="input"
                            type="date"
                            name="date"
                            value={range[1].format("YYYY-MM-DD")}
                            onChange={(date) => {
                              const isValid = moment(
                                date.target.value
                              ).isSameOrAfter(range[0])
                              if (isValid) {
                                setRange([range[0], moment(date.target.value)])
                              } else {
                                alert("End date must be after start date")
                              }
                            }}
                          />
                        </ItemContainer>
                      </Row>
                      <Row>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[1]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[1] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Monday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[2]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[2] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Tuesday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[3]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[3] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Wednesday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[4]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[4] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Thursday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[5]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[5] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Friday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[6]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[6] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Saturday"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={days[0]}
                              onChange={(e) => {
                                const newDays = [...days]
                                newDays[0] = e.target.checked
                                setDays(newDays)
                              }}
                            />
                          }
                          label="Sunday"
                        />
                      </Row>
                    </>
                  )}
                  <Row>
                    <CustomDropdown
                      ref={productDropdownRef}
                      isShown={(isShown) => {
                        setIsProductDropdownOpen(isShown)
                      }}
                      dropdownContent={
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            height: "100%",
                            width: "100%",
                            flex: 1,
                            // padding: "10px",
                            backgroundColor: "white",
                            borderRadius: "5px",
                            boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                            gap: "10px",
                          }}
                        >
                          <CustomSelect
                            data={products.map((product: any) => {
                              return {
                                label: product.title,
                                value: product._id,
                              }
                            })}
                            selected_id={values.product_id}
                            onSelect={(prod_id: string) => {
                              setFieldValue("time", "")
                              setFieldValue("start_time_id", "")
                              setFieldValue("option_id", "")
                              setFieldValue("product_id", prod_id)
                              setIsProductDropdownOpen(false)
                              productDropdownRef.current?.close()
                            }}
                          />
                        </div>
                      }
                    >
                      <ItemContainer
                        isValidated={values.product_id ? true : false}
                        isDropdownOpen={isProductDropdownOpen}
                      >
                        <span
                          style={{
                            flex: 1,
                          }}
                        >
                          {selectedProductTitle || "Select Product"}
                        </span>
                        <i className="fas fa-angle-down"></i>
                      </ItemContainer>
                    </CustomDropdown>

                    {!isRange && (
                      <ItemContainer isValidated={values.date ? true : false}>
                        <input
                          className="input"
                          type="date"
                          name="date"
                          value={values.date}
                          onChange={(date) => {
                            setFieldValue("date", date.target.value)
                          }}
                        />
                      </ItemContainer>
                    )}
                  </Row>
                  {product && (
                    <Row>
                      <CustomDropdown
                        ref={optionsDropdownRef}
                        isShown={(isShown) => {
                          setIsOptionDropdownOpen(isShown)
                        }}
                        dropdownContent={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              height: "100%",
                              width: "100%",
                              flex: 1,
                              // padding: "10px",
                              backgroundColor: "white",
                              borderRadius: "5px",
                              boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.2)",
                              gap: "10px",
                            }}
                          >
                            <CustomSelect
                              data={product.options.map((option: any) => {
                                return {
                                  label: option.title,
                                  value: option.bokun_code,
                                }
                              })}
                              selected_id={values.option_id}
                              onSelect={(prod_id: string) => {
                                setFieldValue("time", "")
                                setFieldValue("start_time_id", "")
                                setFieldValue("option_id", prod_id)
                                setIsOptionDropdownOpen(false)
                                optionsDropdownRef.current?.close()
                              }}
                            />
                          </div>
                        }
                      >
                        <ItemContainer
                          isValidated={values.option_id ? true : false}
                          isDropdownOpen={isOptionDropdownOpen}
                        >
                          <span
                            style={{
                              flex: 1,
                            }}
                          >
                            {selectedOptionTitle || "Select Option"}
                          </span>
                          <i className="fas fa-angle-down"></i>
                        </ItemContainer>
                      </CustomDropdown>
                      <ItemContainer isValidated={values.time ? true : false}>
                        <select
                          value={values.start_time_id}
                          onChange={(bokun_start_time_id) => {
                            const time_slot = selectedProductTimeSlots.find(
                              (time_slot: any) =>
                                time_slot.bokun_start_time_id ===
                                bokun_start_time_id.target.value
                            )
                            setFieldValue(
                              "start_time_id",
                              time_slot.bokun_start_time_id
                            )
                            setFieldValue("time", time_slot.time_slot)

                            setTimeout(() => {
                              validateForm()
                            }, 50)
                          }}
                        >
                          <option value="">Select Time</option>
                          {selectedProductTimeSlots?.map((time_slot: any) => {
                            return (
                              <option
                                key={uuidv4()}
                                value={time_slot.bokun_start_time_id}
                              >
                                {`${time_slot.time_slot} (${time_slot.label})`}
                              </option>
                            )
                          })}
                        </select>
                      </ItemContainer>
                    </Row>
                  )}
                  <Row>
                    <CustomDropdown
                      ref={guideDropdownRef}
                      isShown={(isShown) => {
                        setIsGuideDropdownOpen(isShown)
                      }}
                      dropdownContent={
                        <CustomSelect
                          data={tour_guides.map((guide: any) => {
                            return {
                              label: guide.name,
                              value: guide._id,
                            }
                          })}
                          selected_id={values.guide_id}
                          onSelect={(guide_id: string) => {
                            setFieldValue("guide_id", guide_id)
                            setIsGuideDropdownOpen(false)
                            guideDropdownRef.current?.close()
                          }}
                        />
                      }
                    >
                      <ItemContainer
                        isValidated={!values.guide_id ? false : true}
                        isDropdownOpen={isGuideDropdownOpen}
                      >
                        <span
                          style={{
                            flex: 1,
                          }}
                        >
                          {selectedGuideName || "Select Guide"}
                        </span>
                        <i className="fas fa-angle-down"></i>
                      </ItemContainer>
                    </CustomDropdown>
                    <ItemContainer isValidated={values.index ? true : false}>
                      <select
                        name="index"
                        value={values.index}
                        onChange={(index) => {
                          setFieldValue("index", index.target.value)
                        }}
                      >
                        <option value="1">Group 1</option>
                        <option value="2">Group 2</option>
                        <option value="3">Group 3</option>
                        <option value="4">Group 4</option>
                        <option value="5">Group 5</option>
                        <option value="6">Group 6</option>
                      </select>
                    </ItemContainer>
                  </Row>
                </RowsContainer>
              </ContentContainer>
              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#6eafbb",
                  }}
                  onClick={() => {
                    resetForm()
                    setTimeout(() => {
                      validateForm()
                    }, 50)
                  }}
                >
                  CLEAR
                </Button>
                <Button
                  style={{
                    backgroundColor: "white",
                    border: "1px solid grey",
                    color: "black",
                    flex: 4,
                  }}
                  title="Submit"
                  type="submit"
                  onClick={() => {
                    if (!isValid) {
                      const errorsString = Object.values(errors).join("\n")
                      alert(errorsString)
                    } else {
                      if (!isRange) {
                        const exists = tourArrangementList.find(
                          (tourArrangement) =>
                            //tourArrangement.guide_id === values.guide_id &&
                            tourArrangement.product_id === values.product_id &&
                            tourArrangement.date === values.date &&
                            tourArrangement.time === values.time
                        )

                        if (exists) {
                          alert("This tour assignment already exists")
                        } else {
                          setTourArrangementList([
                            ...tourArrangementList,
                            {
                              product_id: values.product_id,
                              option_id: values.option_id,
                              guide_id: values.guide_id,
                              date: values.date,
                              time: values.time,
                              start_time_id: values.start_time_id,
                              index: values.index,
                            },
                          ])
                          resetForm()
                          setTimeout(() => {
                            validateForm()
                          }, 50)
                        }
                      } else {
                        //add one for every day in the range based on the days array using moment.js

                        const startDate = moment(range[0])
                        const endDate = moment(range[1])

                        const daysToAdd = []
                        for (
                          let i = 0;
                          i <= endDate.diff(startDate, "days");
                          i++
                        ) {
                          const currentDate = startDate.clone().add(i, "days")
                          const dayOfWeek = currentDate.day()
                          if (days[dayOfWeek]) {
                            const newTourAssignment = {
                              product_id: values.product_id,
                              option_id: values.option_id,
                              guide_id: values.guide_id,
                              date: currentDate.format("YYYY-MM-DD"),
                              time: values.time,
                              start_time_id: values.start_time_id,
                              index: values.index,
                            }
                            // Check if the same object is already in the list
                            const isAlreadyAdded = tourArrangementList.some(
                              (item) =>
                                item.product_id ===
                                  newTourAssignment.product_id &&
                                item.option_id ===
                                  newTourAssignment.option_id &&
                                item.date === newTourAssignment.date &&
                                item.time === newTourAssignment.time &&
                                item.start_time_id ===
                                  newTourAssignment.start_time_id &&
                                item.index === newTourAssignment.index
                            )
                            if (!isAlreadyAdded) {
                              daysToAdd.push(newTourAssignment)
                            }
                          }
                        }
                        setTourArrangementList([
                          ...tourArrangementList,
                          ...daysToAdd,
                        ])
                        resetForm()
                        setTimeout(() => {
                          validateForm()
                        }, 50)
                      }
                    }
                  }}
                >
                  <PlaylistAddCircleIcon
                    style={{
                      fontSize: "20px",
                    }}
                  />
                  <span>ADD TO LIST</span>
                </Button>
              </ButtonWrapper>
              <AddTourArrangementActionList
                tourArrangementList={sortedTourArrangementListByDate}
                setTourArrangementList={setTourArrangementList}
                submittingItems={submittingItems}
                submittedItems={submittedItems}
                submittedErrorItems={submittedErrorItems}
              />
              <ButtonWrapper>
                <Button
                  style={{
                    backgroundColor: "#747474",
                    flex: 2,
                  }}
                  onClick={() => {
                    //remove all items from the list
                    setTourArrangementList([])
                  }}
                >
                  EMPTY LIST
                </Button>
                <Button
                  style={{
                    backgroundColor: "#609C72",
                    flex: 4,
                  }}
                  onClick={() => {
                    if (tourArrangementList.length === 0) {
                      alert(
                        "Please add at least one tour assignment to the list"
                      )
                    } else {
                      //console.log(tourArrangementList)
                      initiateSubmission()
                    }
                  }}
                >
                  SUBMIT
                </Button>
              </ButtonWrapper>
            </Modal>
          </Wrapper>
        )
      }}
    </Formik>
  )
}

export default AddTourArrangementModal
