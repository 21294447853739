import { useEffect, useRef, useState } from "react";
import { useFormikContext } from "formik";
import styled from "styled-components";
// import BarLoader from "react-spinners/BarLoader";

//---------------------------------------------------------

const MapWrapper = styled.div`
  min-width: 100%;
  min-height: 100%;
  min-height: 400px;
  border-radius: 5px;
  border: 1px solid lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  .spinner {
    animation: spin 1s linear infinite;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

const MapContainer = styled.span`
  width: 100%;
  height: 100%;
`;

//---------------------------------------------------------

const FindLocationMap = () => {
  const {
    values,
    setFieldValue,
  }: {
    values: any;
    setFieldValue: any;
  } = useFormikContext();

  const mapContainerRef = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<mapboxgl.Map>();
  const marker = useRef<mapboxgl.Marker | null>(null);

  useEffect(() => {
    if (mapContainerRef.current) {
      // @ts-ignore

      if (!map) {
        //@ts-ignore
        const newMap = new mapboxgl.Map({
          container: mapContainerRef.current,
          style: "mapbox://styles/mapbox/streets-v11",
          center: [23.7275, 37.9838],
          zoom: 15,
        });
        setMap(newMap);
      }

      if (map) {
        //@ts-ignore
        marker.current = new mapboxgl.Marker({
          draggable: true,
        })
          .setLngLat([23.7275, 37.9838])
          .setPopup(
            //@ts-ignore
            new mapboxgl.Popup({
              offset: 25,
              closeButton: false, // close button causes aria-hidden error
            }).setHTML(`Drag to position`)
          )
          .addTo(map)
          .togglePopup()
      }

      if (marker.current) {
        marker.current.on("dragend", () => {
          const newLngLat = marker.current?.getLngLat();
          const newLatitude = newLngLat?.lat;
          const newLongitude = newLngLat?.lng;
          setFieldValue("location.latitude", newLatitude);
          setFieldValue("location.longitude", newLongitude);
          setFieldValue(
            "google_maps_url",
            `https://www.google.com/maps/place/${newLatitude},${newLongitude}`
          );
          marker.current?.togglePopup();
        });
      }
    }

    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [setFieldValue, map]);

  // Update marker position  and map center when latitude or longitude changes
  useEffect(() => {
    if (map) {
      const hasLatLng = values.location.latitude && values.location.longitude;

      if (marker.current && hasLatLng) {
        marker.current.setLngLat([
          values.location.longitude,
          values.location.latitude,
        ]);
        map.jumpTo({
          center: [values.location.longitude, values.location.latitude],
        });

        map.resize();
      }
    }
  }, [values.location.latitude, values.location.longitude, map]);

  return (
    <MapWrapper>
      <MapContainer ref={mapContainerRef} />
    </MapWrapper>
  );
};

export default FindLocationMap;
