import { useState, useEffect, useRef, RefObject } from "react";
import { storage } from "../../../../../firebase";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import ProductImageCard from "./ProductImageCard";
import { Button } from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

//-----------------------------------------------------------

function LinearProgressWithLabel(props: any) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};
//-----------------------------------------------------------

const ProductImagesList = ({ productId }: { productId: string }) => {
  const [isFetchingFiles, setIsFetchingFiles] = useState(false);
  const [folderFilesUrls, setFolderFilesUrls] = useState<
    { filename: string; url: string }[] | undefined
  >([]);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileInputRef: RefObject<HTMLInputElement> = useRef(null);

  useEffect(() => {
    const getFiles = async () => {
      try {
        const storageRef = ref(storage, `/products/${productId}/images`);
        const result = await listAll(storageRef);
        const urlPromises = result.items.map(async (imageRef) => {
          const downloadURL = await getDownloadURL(imageRef);
          const filename = imageRef.name;
          return { url: downloadURL, filename };
        });
        return Promise.all(urlPromises);
      } catch (error) {
        console.error("Error occurred:", error);
      }
    };

    const getUrls = async () => {
      try {
        setIsFetchingFiles(true);
        const urls = await getFiles();
        setFolderFilesUrls(urls);
      } catch (error) {
        console.error("Error occurred:", error);
        alert("Error fetching files");
      } finally {
        setIsFetchingFiles(false);
      }
    };

    getUrls();
  }, [productId]);

  // Handle file upload using uploadBytesResumable
  const handleFileUpload = async (file: any) => {
    if (file) {
      try {
        setIsUploadingFile(true); // Set isUploadingFile to true while the file is being uploaded

        // Get the reference to the file in Firebase Storage
        const storageRef = ref(
          storage,
          `/products/${productId}/images/${file.name}`
        );

        // Create a resumable upload task
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, such as progress updates
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Calculate the upload progress percentage
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          (error) => {
            console.error("Error uploading file:", error);
            setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
          },
          () => {
            // Upload completed
            setIsUploadingFile(false); // Reset isUploadingFile state after successful upload
          }
        );

        // Await the completion of the upload
        const uploadTaskSnapshot = await uploadTask;

        // Get the download URL of the uploaded file
        const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

        // Add the uploaded file URL and filename to the folderFilesUrls state
        setFolderFilesUrls((prevUrls: any) => [
          ...prevUrls,
          { url: downloadURL, filename: file.name },
        ]);
      } catch (error) {
        console.error("Error uploading file:", error);
        setIsUploadingFile(false); // Reset isUploadingFile state in case of an error
      }
    }
  };

  //-----------------------------------------------------------

  return (
    <div>
      {isFetchingFiles && <span>Fetching pictures...</span>}
      {!isFetchingFiles && folderFilesUrls && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            padding: "4px",
          }}
        >
          {isUploadingFile ? (
            <Box sx={{ width: "100%" }}>
              <LinearProgressWithLabel value={uploadProgress} />
            </Box>
          ) : (
            <>
              <input
                ref={fileInputRef}
                style={{
                  display: "none",
                }}
                type="file"
                accept="image/jpeg, image/png, image/webp"
                onClick={(e) => {
                  if (fileInputRef.current) {
                    //ts
                    fileInputRef.current.click()
                  }
                }}
                onChange={(e: any) => {
                  const file = e.target.files[0]
                  if (file && file.size < 524288) {
                    // 1MB in bytes
                    handleFileUpload(file)
                  } else {
                    // Handle error, e.g., show a message to the user
                    alert("Invalid file or file size exceeds 0.5MB.")
                  }
                }}
              />
              <Button
                variant="contained"
                size="large"
                onClick={(e) => {
                  if (fileInputRef.current) {
                    fileInputRef.current.click()
                  }
                }}
              >
                UPLOAD PRODUCT IMAGES
              </Button>
            </>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
              gap: "15px",
            }}
          >
            {folderFilesUrls.map((file) => {
              return (
                <ProductImageCard
                  key={file.url}
                  file={file}
                  productId={productId}
                  setFolderFilesUrls={setFolderFilesUrls}
                />
              )
            })}
          </div>
        </div>
      )}
    </div>
  )
};

export default ProductImagesList;
