import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

//---------------------------------------------------------------------------

const CustomMaterialSelect = ({
  values,
  selected,
  label,
  set,
  error,
}: {
  values: Array<any>
  selected: string
  label: string
  set: any
  error: boolean
}) => {
  const selectedValue = values.find((v) => v.value === selected) || null
  return (
    <Autocomplete
      freeSolo
      //size="medium"
      sx={{
        flex: 1,
        minWidth: 200,
      }}
      autoComplete
      // isOptionEqualToValue={(option, value: any) => {
      //   return option.value === value
      // }}
      options={values}
      renderInput={(params) => (
        <TextField
          margin="dense"
          //multiline
          {...params}
          label={label}
          error={error}
          color="success"
        />
      )}
      onChange={(e, value) => {
        set(value?.value || "")
      }}
      getOptionLabel={(option) => {
        const label = values.find((v: any) => v.value === option.value)
        return label?.label || ""
      }}
      value={selectedValue}
    />
  )
}

export default CustomMaterialSelect;
