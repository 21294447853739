import { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import stringSimilarity from "string-similarity";
import $ from "jquery";
import { useAxios } from "../../../../../axiosProvider";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import {
  useFetchEntities,
  useSortProducts,
} from "../../../../../reactQueryHooks";

//---------------------------------------------------------

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const TopContainer = styled.div`
  width: 100%;
  height: 30px;
`;

const Input = styled.input`
  flex: 1;
  width: 100%;
  height: 100%;
  //border: 1px solid lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  padding: 0 8px;
  /* text-align: center; */

  font-size: 13px;
`;

const ListContainer = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 0 5px 0 0;
  overflow-y: auto;
  overflow-x: hidden;
`;

const Product = styled.div`
  font-size: 13px;
  padding: 3px 6px;
  border-radius: 4px;
  cursor: row-resize;
  &:hover {
    background-color: whitesmoke;
  }
`;

//---------------------------------------------------------

const ProductsList = ({ onSelect, products }) => {
  const { refetch } = useFetchEntities();
  const [searchInput, setSearchInput] = useState("");
  const { user } = useAxios();
  const listRef = useRef();
  const handleInputChange = (event) => {
    setSearchInput(event.target.value);
    listRef.current.scrollTop = 0;
  };
  const userIsAdmin = user.isAdmin;
  const userIsPermittedToSortProducts =
    user.permissions.addNewActivities || userIsAdmin;

  // Calculate the similarity scores
  const similarities = stringSimilarity.findBestMatch(
    searchInput.toLowerCase(),
    products.map((mp) => mp.title.toLowerCase())
  );

  // Get the most similar strings
  const mostSimilarStrings = similarities.ratings.map((rating, index) => ({
    string: products.map((mp) => mp.title)[index],
    rating: rating.rating,
    id: products.map((mp) => mp._id)[index],
    //index : should be product.index not rating.index
    index: products.map((mp) => mp.index)[index],
  }));

  // Sort by similarity score (higher score means more similar)
  const sorted = mostSimilarStrings.sort((a, b) => b.rating - a.rating);
  //put admin first, then mods, then users
  sorted.sort((a, b) => {
    if (a.isAdmin && b.isAdmin) {
      return 0;
    } else if (a.isAdmin) {
      return -1;
    } else if (b.isAdmin) {
      return 1;
    } else if (a.isModerator && b.isModerator) {
      return 0;
    } else if (a.isModerator) {
      return -1;
    } else if (b.isModerator) {
      return 1;
    } else {
      return 0;
    }
  });

  const { mutateAsync: sortProducts, isLoading: isSortingProducts } =
    useSortProducts();

  useEffect(() => {
    if (listRef.current) {
      $(listRef.current).sortable({
        //handle: ".sorting-overlay",
        //scrollSensitivity: 1,
        scrollSpeed: 4,
        containment: ".list-container",
        start: function (event, ui) {
          if (!userIsPermittedToSortProducts) {
            alert("You are not permitted to sort bookings");
            return;
          }
        },
        update: async (event, ui) => {
          try {
            const ids = $(event.target).sortable("toArray");
            const response = await sortProducts(ids);
            toast.success(response?.data);
            refetch();
          } catch (e) {
            alert(e.toString());
          }
        },
      });
    }
  }, [userIsPermittedToSortProducts, sortProducts, refetch]);

  //--------------------------------------------------------------------

  return (
    <Wrapper>
      <TopContainer>
        <Input
          autoFocus
          placeholder="Search..."
          autoCapitalize="none"
          autoComplete="off"
          autoCorrect="off"
          spellCheck="false"
          value={searchInput}
          onChange={handleInputChange}
        />
      </TopContainer>
      <ListContainer ref={listRef} className="list-container">
        {isSortingProducts && (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ThreeDots
              height="30"
              width="30"
              radius="9"
              color="grey"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        )}

        {!isSortingProducts &&
          sorted.map((item, index) => {
            return (
              <Product
                className="sorting-overlay"
                key={`${item.id}_${index}`}
                id={item.id}
                data-index={index}
                onClick={() => {
                  const product = products.find((i) => i._id === item.id)
                  onSelect(product)
                }}
              >
                <span
                  style={{
                    color: "dodgerblue",
                  }}
                >
                  #{item.index}{" "}
                </span>
                {item.string}
              </Product>
            )
          })}
      </ListContainer>
    </Wrapper>
  );
};

export default ProductsList;
